@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@300&display=swap");

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -50%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeInDown {
  animation: fadeInDown 1s ease-out;
}

.fadeIn {
  animation: fadeIn 1.3s ease-out;
}

.hide {
  opacity: 0 !important;
}
