@keyframes float {
  0% {
    transform: translate(0px, 0px);
  }
  20% {
    transform: translate(3px, 3px);
  }
  40% {
    transform: translate(-3px, 0px);
  }
  60% {
    transform: translate(0px, -3px);
  }
  80% {
    transform: translate(3px, 0px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

.skill_icon {
  animation: float 3s linear infinite;
}
